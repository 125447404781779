<script setup lang="ts">
import OzBaseButton from '@@/library/v4/components/OzBaseButton.vue'

withDefaults(
  defineProps<{
    /**
     * Dark mode.
     */
    darkMode?: 'auto' | boolean

    /**
     * URL where the link points to.
     */
    href?: string
    hrefTarget?: '' | string
    colorPreset?: OzTextLinkColorPreset

    /**
     * Text.
     */
    text: string
  }>(),
  {
    darkMode: 'auto',
    hrefTarget: '',
    colorPreset: OzTextLinkColorPreset.Secondary,
  },
)
</script>

<script lang="ts">
export enum OzTextLinkColorPreset {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export default {}
</script>

<template>
  <OzBaseButton
    :class="[
      /**
       * This class is defined in the `tailwind.css` file
       * so it can be used by anchors within custom strings (e.g translations).
       */
      colorPreset === OzTextLinkColorPreset.Primary && {
        'oz-text-link-light-primary': darkMode === false,
        'oz-text-link-dark-primary': darkMode === true,
        'oz-text-link-light-primary dark:oz-text-link-dark-primary': darkMode === 'auto',
      },
      colorPreset === OzTextLinkColorPreset.Secondary && {
        'oz-text-link-light-secondary': darkMode === false,
        'oz-text-link-dark-secondary': darkMode === true,
        'oz-text-link-light-secondary dark:oz-text-link-dark-secondary': darkMode === 'auto',
      },
      '!underline',
    ]"
    :href="href"
    :target="hrefTarget"
    @mousedown="$emit('mousedown', $event)"
    @keydown="$emit('keydown', $event)"
    @click="$emit('click', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
    @touchstart="$emit('touchstart', $event)"
  >
    {{ text }}
  </OzBaseButton>
</template>
